import React, { useState, useEffect, useRef } from 'react';
import './css/addcourse.css';
import axios from '../config/index';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'
function AddCourse() {
    const [categories, setCategories] = useState([]);
    const [userId, setUserId] = useState(null);
    const [usertype, setUsertype] = useState(null);
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // New state for loading screen
    const [formData, setFormData] = useState({
        header: '',
        enrolled_students: 0,
        description: '',
        prev_price: 0,
        price: 0,
        language: '',
        duration: '',
        level: '',
        requirments: '',
        sec_header: '',
        free: 0,
        cat_id: '',
        img: null,
        intro_video: null,
    });

    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const imgInputRef = useRef(null);
    const videoInputRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get('/session')
            .then((res) => {
                if (res.data.valid && res.data.type === "inst") {
                    setUserId(res.data.userId);
                    setUsertype(res.data.type);
                } else {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err));
    }, [navigate]);

    useEffect(() => {
        if (userId) {
            axios.post('/get-user', { itemId: userId })
                .then((res) => {
                    if (!res.data.message) {
                        setUser(res.data);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [userId]);

    useEffect(() => {
        axios.post('/AllItems', {
            table: 'categories',
        })
        .then((res) => {
            if (res.data) {
                setCategories(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log('Form Data value:', name , value);
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({ ...formData, [name]: files[0] });
    };

    const handleUpload = (e) => {

        if (!formData.header) {
            alert('يجب ملئ جميع بيانات الكورس');
            setUploading(false);
            setIsLoading(false);
            return;
        }


        setIsLoading(true); // Show loading screen



        if (!['admin', 'inst'].includes(usertype)) {
            setIsLoading(false);
            return alert('Forbidden: You do not have permission to perform this action');
        }

        const data = new FormData();
        Object.keys(formData).forEach(key => {
            data.append(key, formData[key]);
        });
        data.append('user_id', userId);
        setIsLoading(true); // Show loading screen
        setUploading(true);

        axios.post('/add-course', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(progress);
            }
        })
        .then(response => {
            setUploading(false);
            if (response.data.message) {
                alert(response.data.message);
                navigate('/courses');
            }
        })
        .catch(error => {
            console.error('Upload failed:', error);
            setUploading(false);
        })
        .finally(() => {
            setUploading(false);
            setIsLoading(false); // Hide loading screen
        });
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event, type) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        handleFileChange({ target: { name: type, files: [file] } });
    };

    const handleDivClick = (type) => {
        if (type === 'img') {
            imgInputRef.current.click();
        } else {
            videoInputRef.current.click();
        }
    };
    useEffect(() => {
        console.log('Form Data Updated:', formData);
    }, [formData]);
    return (
        <>
              {isLoading && (
                <div className='loading-screen active'>
                    <div className="loader">
                        <div className="outer"></div>
                        <div className="middle"></div>
                        <div className="inner"></div>
                    </div>
                </div>
            )}
            
        <section className='add-course'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                        <div className='course-tip'>
                            <h1>الملحوظات</h1>
                            <ul>
                                <li>تعيين سعر الكورس أو جعله مجانيًا.</li>
                                <li>الحجم القياسي لصورة الكورس المصغرة هو 700x430.</li>
                                <li>قسم الفيديو يتحكم في فيديو نظرة عامة على الكورس.</li>
                                <li>منشئ الكورس هو المكان الذي تنشئ فيه وتنظم الكورس.</li>
                                <li>أضف المواضيع في قسم منشئ الكورس لإنشاء الدروس، الاختبارات، والتكليفات.</li>
                            </ul>
                        </div>
                    </div>

                    <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                        <div className='course-addition-inputs'>
                            <form>
                                <div className='addition-input'>
                                    <div className='header'>
                                        <i className="las la-plus"></i>
                                        <h1>تفاصيل الكورس</h1>
                                    </div>

                                    <div className='content'>
                                        <div className='input'>
                                            <label>عنوان الكورس</label>
                                            <input required type='text' name='header' placeholder='كورس جديد' maxLength={200} onChange={handleInputChange} />
                                            <span className='note'>العنوان يجب أن لا يقل عن 20 حرف ولا يزيد عن 200 حرف <i className="las la-info-circle"></i></span>
                                        </div>

                                        <div className='input'>
                                            <label>عنوان فرعي للكورس</label>
                                            <input required type='text' name='sec_header' placeholder='كورس جديد' maxLength={250} onChange={handleInputChange} />
                                            <span className='note'>العنوان يجب أن لا يقل عن 20 حرف ولا يزيد عن 250 حرف <i className="las la-info-circle"></i></span>
                                        </div>

                                        <div className='input'>
                                            <label> اللغة </label>
                                            <input required type='text' name='language' placeholder=' مثال : العربية' maxLength={250} onChange={handleInputChange} />
                                            <span className='note'>اللغة يجب أن لا يقل عن 5 حرف ولا يزيد عن 250 حرف <i className="las la-info-circle"></i></span>
                                        </div>

                                        <div className='input'>
                                            <label>مدة الكورس</label>
                                            <input required type='text' name='duration' placeholder='5 Hrs 20 Min' maxLength={250} onChange={handleInputChange} />
                                            <span className='note'>مثال : 5 Hrs 20 Min <i className="las la-info-circle"></i></span>
                                        </div>

                                        <div className='input'>
                                            <label>مستوى الكورس</label>
                                            <input required  type='text' name='level' placeholder='مبتدئ' maxLength={250} onChange={handleInputChange} />
                                            <span className='note'>مثال : مبتدئ <i className="las la-info-circle"></i></span>
                                        </div>

                                        <div className='input'>
                                            <label>عن الكورس</label>
                                            <textarea required name='description' onChange={handleInputChange}></textarea>
                                            <span className='note'>النص العادي أو HTML مسموح به، لا توجد رموز تعبيرية. يستخدم هذا الحقل للبحث، لذا يرجى أن تكون وصفياً! <i className="las la-info-circle"></i></span>
                                        </div>

                                        <div className='input'>
                                            <label>متطلبات الكورس</label>
                                            <textarea required name='requirments' onChange={handleInputChange}></textarea>
                                            <span className='note'>النص العادي أو HTML مسموح به، لا توجد رموز تعبيرية. يستخدم هذا الحقل للبحث، لذا يرجى أن تكون وصفياً! <i className="las la-info-circle"></i></span>
                                        </div>

                                        <div className='special-input'>
                                            <div className='toggle-btns'>
                                                <button
                                                    type="button"
                                                    className={formData.free ? '' : 'active'}
                                                    onClick={() => setFormData({ ...formData, free: 0 })}
                                                >
                                                    مدفوع
                                                </button>
                                                <button
                                                    type="button"
                                                    className={formData.free ? 'active' : ''}
                                                    onClick={() => setFormData({ ...formData, free: 1 })}
                                                >
                                                    مجاني
                                                </button>
                                            </div>

                                            <div className='screens'>
                                                {!formData.free && (
                                                    <div className='screen'>
                                                        <div className='input'>
                                                            <label>السعر قبل الخصم</label>
                                                            <input required type='number' name='prev_price' placeholder='السعر' onChange={handleInputChange} />
                                                            <span className='note'>سيتم خصم نسبة من الثمن تذهب لفريق عمل المنصة <i className="las la-info-circle"></i></span>
                                                        </div>

                                                        <div className='input'>
                                                            <label>السعر بعد الخصم</label>
                                                            <input required type='number' name='price' placeholder='السعر' onChange={handleInputChange} />
                                                            <span className='note'>سيتم خصم نسبة من الثمن تذهب
                                                            لفريق عمل المنصة <i className="las la-info-circle"></i></span>
                                                        </div>
                                                    </div>
                                                )}

                                                {formData.free && (
                                                    <div className='screen'>
                                                        <p>هذا الكورس مجاني لجميع الطلاب</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='input'>
                                            <label>القسم الخاص بالكورس</label>
                                            <select name='cat_id' onChange={handleInputChange} required>
                                                <option value="">اختر القسم</option>
                                                {categories.map((cat) => (
                                                    <option key={cat.id} value={cat.id}>
                                                        {cat.header} ({cat.id})
                                                    </option>
                                                ))}
                                            </select>
                                            <span className='note'>العنوان يجب أن لا يقل عن 20 حرف ولا يزيد عن 250 حرف <i className="las la-info-circle"></i></span>
                                        </div>

                                        <div className='upload-input'>
                                            <label>صورة الكورس</label>

                                            <div
                                                className='drag-drop-here'
                                                onDragOver={handleDragOver}
                                                onDrop={(e) => handleDrop(e, 'img')}
                                                onClick={() => handleDivClick('img')}
                                            >
                                                {formData.img ? (
                                                    <img
                                                        src={URL.createObjectURL(formData.img)}
                                                        alt="Uploaded"
                                                        className='uploaded-image'
                                                    />
                                                ) : (
                                                    <>
                                                        <i className="las la-cloud-upload-alt"></i>
                                                        <h2>اختر الملف</h2>
                                                    </>
                                                )}
                                            </div>

                                            <input
                                                type='file'
                                                name='img'
                                                ref={imgInputRef}
                                                onChange={handleFileChange}
                                                required
                                                style={{ display: 'none' }}
                                            />

                                            <span className='note'>الحجم: 700 × 430 بكسل، دعم الملف: JPG، JPEG، PNG <i className="las la-info-circle"></i></span>
                                        </div>
                                    </div>
                                </div>

                                <div className='addition-input'>
                                    <div className='header'>
                                        <i className="las la-plus"></i>
                                        <h1>الفيديو التوضيحي للكورس</h1>
                                    </div>

                                    <div className='content'>
                                        <div className='upload-input'>
                                            <label>الفيديو التوضيحي للكورس</label>

                                            <div
                                                className='drag-drop-here'
                                                onDragOver={handleDragOver}
                                                onDrop={(e) => handleDrop(e, 'intro_video')}
                                                onClick={() => handleDivClick('intro_video')}
                                            >
                                                {formData.intro_video ? (
                                                    <video
                                                        controls
                                                        className='uploaded-video'
                                                        src={URL.createObjectURL(formData.intro_video)}
                                                    />
                                                ) : (
                                                    <>
                                                        <i className="las la-cloud-upload-alt"></i>
                                                        <h2>اختر الملف</h2>
                                                    </>
                                                )}
                                            </div>

                                            <input
                                                type='file'
                                                name='intro_video'
                                                ref={videoInputRef}
                                                onChange={handleFileChange}
                                                required
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {uploading && (
                                    <div className='uploading'>
                                        <div className='progress'>
                                            <div className='progress-bar' style={{ width: `${uploadProgress}%` }}></div>
                                        </div>
                                        <span>{uploadProgress}%</span>
                                    </div>
                                )}

                                <div className='addition-btns'>
                                    <button
                                    onClick={(e) => {
                                        e.preventDefault();  // Prevent default action
                                        handleUpload()
                                    }}

                                    className='submit-course link-btn-color'> إضافة الكورس <i className="las la-arrow-right"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default AddCourse;
