import './App.css';

import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import 'aos/dist/aos.css';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import LandCategories from './components/LandCategories';
import LandCourses from './components/LandCourses';
import Features from './components/Features';
import Numbers from './components/Numbers';
import AllCoursesFilter from './components/AllCoursesFilter';
import LandEvents from './components/LandEvents';
import Reviews from './components/Reviews';
import LandContact from './components/LandContact';
import Footer from './components/Footer';
import LoginRegister from './components/LoginRegister';
import LoginRegisterInstructor from './components/LoginRegisterInstructor';
import InstProfile from './components/InstProfile';
import InstDash from './components/InstDash';
import StudentDash from './components/StudentDash';
import AddCourse from './components/AddCourse';
import EditCourse from './components/EditCourse';
import CoursePage from './components/CoursePage';
import LessonPage from './components/LessonPage';
import EventsPage from './components/EventsPage';
import CategoriesPage from './components/CategoriesPage';
import CoursesPage from './components/CoursesPage';
import CategoryCourses from './components/CategoryCourses';




// Admin Pages


import SideNav from "./components/Admin/SideNav";
import LoginAdmin from "./components/Admin/LoginAdmin";


import SearchBar from "./components/Admin/SearchBar";
import Dashboard from "./components/Admin/Dashboard";
import AllUsersPage from "./components/Admin/AllUsersPage";
import EditUser from "./components/Admin/EditUser";
import AddUser from "./components/Admin/AddUser";


import AllSocialPage from "./components/Admin/AllSocialPage";
import EditSocial from "./components/Admin/EditSocial";

import AllLanding from "./components/Admin/AllLanding";
import EditLanding from "./components/Admin/EditLanding";


import AllSubscribers from "./components/Admin/AllSubscribers";

import AllCategories from "./components/Admin/AllCategories";
import AddCategory from "./components/Admin/AddCategory";
import EditCategory from "./components/Admin/EditCategory";

import AllCourses from "./components/Admin/AllCourses";

import AllFeatures from "./components/Admin/AllFeatures";
import EditFeature from "./components/Admin/EditFeature";


import AllNumbers from "./components/Admin/AllNumbers";
import EditNumber from "./components/Admin/EditNumber";

import AllEvents from "./components/Admin/AllEvents";
import AddEvent from "./components/Admin/AddEvent";
import EditEvent from "./components/Admin/EditEvent";

import AllRates from "./components/Admin/AllRates";
import AddRate from "./components/Admin/AddRate";
import EditRate from "./components/Admin/EditRate";

import AllLessons from "./components/Admin/AllLessons";

import AllQuestions from "./components/Admin/AllQuestions";


import AllUsersCourses from "./components/Admin/AllUsersCourses";
import AddUsersCourse from "./components/Admin/AddUsersCourse";
import EditUsersCourse from "./components/Admin/EditUsersCourse";
import ConfirmEmail from './components/ConfirmEmail';
import EditUserInfo from './components/EditUserInfo';
import Services from './components/Services';
import HomeRegister from './components/home-register';
import Payment from './components/Payment';
import EditLesson from './components/EditLesson';



function App() {

    
    
    const [sessionExists, setSessionExists] = useState("");
    const location = useLocation();
    const hideHeaderFooter =
    location.pathname === "/dashboard" ||
    location.pathname === "/all-users" ||
    location.pathname === "/edit-user" ||
    location.pathname === "/add-user"|| 

    location.pathname === "/all-subscribers"||

    location.pathname === "/all-landings"||
    location.pathname === "/edit-landing"||

    location.pathname === "/all-categories"||
    location.pathname === "/edit-category"||
    location.pathname === "/add-category"||

    
    location.pathname === "/all-courses"||


    
    location.pathname === "/all-features"||
    location.pathname === "/edit-feature"||

    location.pathname === "/all-numbers"||
    location.pathname === "/edit-number"||

    location.pathname === "/all-events"||
    location.pathname === "/edit-event"||
    location.pathname === "/add-event"||

    location.pathname === "/all-rates"||
    location.pathname === "/edit-rate"||
    location.pathname === "/add-rate"||

    location.pathname === "/all-lessons"||

    location.pathname === "/all-questions"||

    location.pathname === "/all-user-courses"||
    location.pathname === "/edit-user-course"||
    location.pathname === "/add-user-course"||


    location.pathname === "/all-social" ||
    location.pathname === "/edit-social";




return (
    <div className="App">
        

            <Routes>

            
            <Route path="/" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <Landing />
                        <Numbers /> 
                        <Services/>
                        <LandCategories/>
                        <LandCourses />
                        <LandEvents />
                        <Reviews />
                        <HomeRegister />
                         <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />


                
                <Route path="/about-us" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <Features />
                        <Numbers />
                        {/* <AllCoursesFilter /> */}
                        <LandEvents />
                        {/* <LandContact /> */}
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />


                    <Route path="/courses" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <CoursesPage />

                        {/* <AllCoursesFilter /> */}

                        <Payment />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />


                
                    <Route path="/categories" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <CategoriesPage/>


                        {/* <LandContact /> */}
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />

                <Route path="/category" element={
                                    <>
                                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                                        <CategoryCourses/>


                                        {/* <LandContact /> */}
                                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                                    </>
                                } />


                                
                    <Route path="/contact-us" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>


                        <LandContact />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />


                
                                
                    <Route path="/events" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>


                        <EventsPage />

                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />







            <Route path="/login-register" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <LoginRegister />
                        {/* <LandContact /> */}
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />
            <Route path="/register-ins" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <LoginRegisterInstructor />
                        {/* <LandContact /> */}
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />
                <Route path="/confirm-email" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <ConfirmEmail />
                        {/* <LandContact /> */}
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />



                <Route path="/instructor-profile" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <InstProfile />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />


                

                <Route path="/instructor-dashboard" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <InstDash />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />


                
                <Route path="/student-dashboard" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <StudentDash />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />

                
                <Route path="/add-course" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <AddCourse />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />

                                
                <Route path="/edit-course" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <EditCourse />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />
                
                <Route path="/edit-lesson" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <EditLesson/>
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />

                <Route path="/course" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <CoursePage />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />
                 <Route path="/payment" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <Payment />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />

                <Route path="/lesson" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <LessonPage />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />

                <Route path="/user-settings" element={
                    <>
                        <Navbar  className={!hideHeaderFooter ? "" : "hide"}/>
                        <EditUserInfo />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>
                    </>
                } />


                



                
<Route path="/admin-login"element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><LoginAdmin sessionExists={sessionExists} setSessionExists={setSessionExists} /></>}/>
            <Route path="/dashboard"element={ <><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><Dashboard /> </>}/>
            <Route path="/all-users" element={ <><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllUsersPage /> </>}/>
            <Route path="/edit-user" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><EditUser /></>} />
            <Route path="/add-user" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AddUser /></>}/>
            <Route path="/all-users"element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllUsersPage /></> }/>


            <Route path="/all-landings" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllLanding /></> }/>
            <Route path="/edit-landing" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><EditLanding /></> }/>


            <Route path="/all-subscribers" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllSubscribers /></> }/>

            <Route path="/all-categories" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllCategories /></> }/>
            <Route path="/add-category" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AddCategory /></> }/>
            <Route path="/edit-category" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><EditCategory /></> }/>

            <Route path="/all-courses" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllCourses /></> }/>

            <Route path="/all-features" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllFeatures /></> }/>
            <Route path="/edit-feature" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><EditFeature /></> }/>

            <Route path="/all-numbers" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllNumbers /></> }/>
            <Route path="/edit-number" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><EditNumber /></> }/>


            <Route path="/all-events" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllEvents /></> }/>
            <Route path="/add-event" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AddEvent /></> }/>
            <Route path="/edit-event" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><EditEvent /></> }/>


            <Route path="/all-rates" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllRates /></> }/>
            <Route path="/add-rate" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AddRate /></> }/>
            <Route path="/edit-rate" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><EditRate /></> }/>

            <Route path="/all-lessons" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllLessons /></> }/>

            <Route path="/all-questions" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllQuestions /></> }/>

            <Route path="/all-user-courses" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllUsersCourses /></> }/>


            <Route path="/add-user-course" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />

        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AddUsersCourse /></> }/>


<Route path="/edit-user-course" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />

        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><EditUsersCourse /></> }/>



                
        <Route path="/all-social" element={
            <>

        <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>

        <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
        
        <AllSocialPage />
        
        </> }/>


        <Route path="/edit-social" element={<>

            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>

            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>

            <EditSocial />

            </> }/>


        </Routes>





        
    </div>
);
}

export default App;
